import { useQuery } from "@tanstack/react-query";

import * as services from "./BrandHome.services";
import * as types from "./BrandHome.types";
import {
  ActionDescription,
  ActionHeading,
  ActionIconCont,
  BannerCont,
  BannerContent,
  BannerDescription,
  BannerGradient,
  BannerHeading,
  BrandHomePageCont,
  DemoCardCont,
  DemoCont,
  DemoDescription,
  DemoThumbnailCont,
  PendingActionCardCont,
  PendingActionsCont,
  SectionCont,
  SectionDescription,
  SectionHeading,
} from "./BrandHome.styles";
import { Button } from "../../Components";
import newUserBannerImg from "../../Assets/Images/new_user_banner.png";
import { ICONS } from "../../Assets/Icons/Icons";
import { useNavigate } from "react-router-dom";

function Home() {
  const { data, isPending } = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => services.getDashboard(),
  });
  const navigate = useNavigate();

  const dashboard = data?.result;

  return isPending ? (
    <></>
  ) : (
    <BrandHomePageCont>
      {/* PENDING ACTIONS */}
      <SectionCont>
        <SectionHeading>{dashboard?.actions.label}</SectionHeading>
        <SectionDescription>
          {dashboard?.actions.description}
        </SectionDescription>
        <PendingActionsCont>
          {dashboard?.actions?.cards.map((curr, i) => {
            return <PendingActionCard card={curr} key={i} />;
          })}
        </PendingActionsCont>
      </SectionCont>
      {/* NEW USER BANNER */}
      {dashboard?.create_campaign && (
        <BannerCont>
          <BannerGradient />
          <BannerContent>
            <BannerHeading>{dashboard.create_campaign.label}</BannerHeading>
            <BannerDescription>
              {dashboard.create_campaign.description}
            </BannerDescription>
            <Button
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
              }}
              text={dashboard.create_campaign.cta.label}
              type="primary"
              onClick={() => {
                navigate("/brand/create-campaign");
              }}
              iconPosition="start"
              icon={<img src={ICONS.plus} />}
            />
          </BannerContent>
          <img src={newUserBannerImg} />
        </BannerCont>
      )}

      {/* DEMO  */}
      <SectionCont>
        <SectionHeading>{dashboard?.demo.label}</SectionHeading>
        <SectionDescription>{dashboard?.demo.description}</SectionDescription>
        <DemoCont>
          {dashboard?.demo?.cards.map((curr, i) => {
            return <DemoCard card={curr} key={i} />;
          })}
        </DemoCont>
      </SectionCont>
    </BrandHomePageCont>
  );
}

export default Home;

const PendingActionCard = ({ card }: { card: types.ActionCard }) => {
  return (
    <PendingActionCardCont>
      <ActionIconCont>
        <img src={ICONS[card.icon]} />
      </ActionIconCont>
      <ActionHeading>{card.title}</ActionHeading>
      <ActionDescription>{card.description}</ActionDescription>
      <Button
        style={{
          height: "40px",
          display: "flex",
          alignItems: "center",
          fontWeight: "600",
        }}
        text={card.cta.label}
        type="primary"
        onClick={() => {}}
        iconPosition="end"
        icon={<img src={ICONS.arrowRight} />}
      />
    </PendingActionCardCont>
  );
};

const DemoCard = ({ card }: { card: types.DemoCard }) => {
  return (
    <DemoCardCont>
      <DemoThumbnailCont onClick={() => window.open(card.url, "_blank")}>
        <img src={card.thumbnail} alt={card.description} />
      </DemoThumbnailCont>
      <DemoDescription>{card.description}</DemoDescription>
    </DemoCardCont>
  );
};
