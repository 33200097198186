import StatusCorrect from "./StatusCorrect.svg";
import StatusWrong from "./StatusWrong.svg";
import home from "./home.svg";
import megaphone from "./megaphone.svg";
import settings from "./settings.svg";
import logout from "./logout.svg";
import ThreeDot from "./ThreeDot.svg";
import Google from "./Google.svg";
import image_55 from "./image_55.svg";
import image_56 from "./image_56.svg";
import arrowRight from "./arrow-right.svg";
import plus from "./plus.svg";

export const ICONS = {
  StatusCorrect,
  StatusWrong,
  home,
  megaphone,
  settings,
  logout,
  ThreeDot,
  Google,
  image_55,
  image_56,
  arrowRight,
  plus,
};
